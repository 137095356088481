<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="home">
        <router-view/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Config',
  components: {
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
